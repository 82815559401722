<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  content: Array<StoryblokComponent>;
}>();

const useThreeColumnsGrid = computed(() => {
  return props.content.length > 2;
});
</script>

<template>
  <ul
    class="base-three-column-list"
    :class="[
      'base-three-column-list',
      {
        'base-three-column-list--4/4/4': useThreeColumnsGrid,
      },
    ]"
  >
    <li v-for="item of content" :key="item._uid">
      <component :is="resolveComponent(item.component)" :blok="item" />
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.base-three-column-list {
  @apply mx-auto grid gap-4 justify-center;

  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @screen xs {
    @apply gap-8;
    grid-template-columns: repeat(2, minmax(0, 50%));
  }

  @screen md {
    grid-template-columns: repeat(2, minmax(0, calc(100% / 3)));

    &--4\/4\/4 {
      grid-template-columns: repeat(3, minmax(0, calc(100% / 3)));
    }
  }
}
</style>
